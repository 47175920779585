.admin-wrapper {
    background-color: var(--grey-primary);
    color: var(--text-primary) !important;
    width: 100%;
    padding: 0 0px 0 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

/* .admin-wrapper h2 {
    color: var(--text-primary) !important;
    margin: 0;
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
} */

.admin-widget {
    margin: 4px 4px 8px 12px;
    width: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--grey-secondary);
    border-radius: 8px;
    padding: 16px;
    position: relative;
}

.admin-widget-title {
    cursor: pointer;
    width: 100%;
}

.admin-widget-content {
    max-height: 0;
    opacity: 0;
    width: 100%;
    transition: opacity 0.2s, max-height 0.5s ease;
    pointer-events: none;
}

.admin-widget-content.visible {
    display: block;
    overflow-x: hidden;
    opacity: 1;
    max-height: 10000px;
    width: 100%;
    transition: opacity 0.2s, max-height 0.5s ease;
    pointer-events: all;
}

.admin-subwidget {
    width: calc(100% - 22px);
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: var(--grey-primary);
    border-radius: 8px;
    padding: 12px;
    position: relative;
    margin-bottom: 12px;
}

.admin-subwidget admin-widget-title {
    cursor: pointer;
}

.admin-subwidget-title {
    margin-bottom: 8px;
}

.admin-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin-input-wrapper label {
    width: fit-content;
    text-wrap: nowrap;
    color: var(--text-secondary);
    font-size: 14px;
    padding: 12px;
}

.admin-input {
    background-color: var(--grey-tertiary);
    color: var(--text-secondary);
    border-radius: 4px;
    border: none;
    padding: 8px;
    margin-top: 2px;
    margin-bottom: 8px;
    width: calc(100% - 16px);
    max-width: calc(100% - 16px);
    font-size: 14px;
    transition: all 0.3s;
    border-bottom: 2px solid var(--accent-color-dark);
    outline: none;
    max-height: 32px !important;
    cursor: text;
    flex-direction: row;
}

.admin-input:focus:not(:disabled) {
    border-bottom: 2px solid var(--accent-color);
}

.admin-input::placeholder {
    font-weight: 100;
    font-size: 14px;
}

.admin-dropdown {
    background-color: var(--grey-tertiary);
    color: var(--text-secondary);
    border-radius: 4px;
    border: none;
    padding: 6px;
    margin-top: 4px;
    margin-bottom: 8px;
    border-bottom: 2px solid var(--accent-color-dark);
    width: calc(100% - 16px);
    max-width: calc(100% - 16px);
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    height: 32px;
    cursor: pointer;
}

.admin-dropdown:focus:not(:disabled) {
    border-bottom: 2px solid var(--accent-color);
}

.admin-button-confirm {
    background-color: rgb(81, 179, 86) !important;
    color: black !important;
    transition: all 0.5s ease-in-out;
}

.admin-button {
    background-color: var(--grey-tertiary);
    color: var(--text-primary);
    border-radius: 4px;
    border: none;
    padding: 8px;
    margin-top: 2px;
    width: 100%;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 2px solid var(--accent-color-dark);
    transition: all 0.5s ease-in-out;
}

.admin-button span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
}

.admin-widget span {
    line-height: 1.3;
}

input[type="checkbox"] {
    display: none;
}

.admin-checkbox {
    position: relative;
    cursor: pointer;
    user-select: none;
    color: var(--text-secondary);
    font-size: 14px;
    padding: 0;
}

.admin-checkbox::after {
    content: "";
    position: absolute;
    margin-left: 12px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--grey-tertiary);
    border: 4px solid var(--grey-tertiary);
    transition: background-color 0.3s ease;
}

.admin-checkbox-checked::after {
    content: "✔";
    text-align: center;
    background-color: var(--accent-color);
    transition: background-color 0.3s ease;
}

.admin-teacher-teacher-widget {
    margin: 5px;
    width: 600px;
    min-height: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    background-color: var(--grey-secondary);
    border-radius: 8px;
    padding: 16px;
    position: relative;
}

.admin-teacher-widget h1 {
    text-align: left;
    line-height: 1;
    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    margin: 0;
    margin-bottom: 12px;
}

.admin-teacher-widget p {
    text-align: left;
    margin: 0;
    font-weight: 200;
    color: var(--text-secondary)
}

.material-success {
    background-color: rgb(55, 161, 55);
    transition: all 0.3s ease-in-out;
    content: "✔";
}

#admin-mat-options {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}

.admin-mat-option {
    display: flex;
    flex-direction: column;
    color: white;
    border-radius: 4px;
    align-items: center;
    border: none;
    height: 80px !important;
    width: calc(33% - 16px);
}

#fake-admin-button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 32px !important;
    background-color: var(--grey-tertiary);
    border-radius: 4px;
    margin-top: 2px;
    padding-top: 2px;
    width: 100%;
    height: 32px;
    text-align: center;
    cursor: pointer;
    flex-direction: row;
    border-bottom: 2px solid var(--accent-color-dark);
}

#admin-file-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.admin-confirmation {
    align-self: flex-end;
    align-items: center;
    margin-top: auto;
    width: 100%;
}

.admin-confirmation div {
    width: calc(100% - 8px);
}

.checkbox-label {
    padding-bottom: 16px;
}

.admin-confirmation button {
    margin-top: 12px;
}

.topics-wrapper {
    display: flex;
    flex-direction: column;
}

.topic-item {
    background-color: var(--grey-secondary);
    padding: 8px;
    border-radius: 4px;
    border-left: 4px var(--accent-color) solid;
    margin-bottom: 8px;
    margin-top: 4px;
}

.subtopic-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
    background-color: var(--grey-tertiary);
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-top: 4px;
}


.admin-delete-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 12px;
    width: 26px;
    margin-top: 4px;
    cursor: pointer;
}

.add-button {
    background-color: var(--accent-color);
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    margin-left: 4px;
    height: 32px;
    margin-bottom: 4px;
    cursor: pointer;
}

/* make a grid */
.users-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    border-radius: 8px;
    background-color: var(--grey-secondary);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.user-info-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: var(--grey-primary);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 16px;
    margin: 8px 4px 4px 8px;
    line-height: 2vh;
    flex-basis: 300px;
    flex-grow: 1;

}

.user-name-info {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-primary);
}

.user-name-info span {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-secondary);
}

.access-denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 500px) {
    #admin-mat-options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .admin-mat-option {
        display: flex;
        flex-direction: column;
        color: white;
        border-radius: 4px;
        align-items: center;
        border: none;
        height: 80px !important;
        width: 100%;
    }
}



/* KOPYAMAKARNA  */
/* .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* KOPYMAKARNA END */