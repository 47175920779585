/*#region Global Definitions*/

:root {
    --accent-color: rgb(50, 126, 176);
    --accent-color-dark: rgb(0, 99, 156);
    --grey-primary: rgb(12, 20, 25);
    --grey-secondary: rgb(23, 36, 44);
    --grey-tertiary: rgb(36, 52, 62);
    --text-primary: rgb(255, 255, 255);
    --text-secondary: rgb(212, 212, 212);
    --alert-secondary: rgb(176, 51, 51);
    --shadow-tint: rgba(0, 0, 0, 0.24);
    --frosted-blue: rgba(21, 86, 124, 0.5);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    position: relative;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--grey-tertiary);
    border-radius: 8px;
    animation: ease-in-out 0.3s;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--grey-secondary);
    animation: ease-in-out 0.3s;
}

body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--grey-primary);
}

.page-wrapper {
    overflow-x: hidden !important;
    color: var(--text-primary);
    background-color: var(--grey-primary);
    max-width: 100vw;
    min-height: 100vh;
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

.icon-button {
    background-color: var(--grey-tertiary);
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
    font-weight: 600;
    width: 42px;
    height: 42px;
}

.generic-button {
    background-color: var(--accent-color);
    border-radius: 8px;
    height: 20px;
    border: none;
    cursor: pointer;
    color: var(--text-primary);
    font-weight: 600;
    height: 42px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.generic-button span {
    margin-left: 8px;
    margin-right: 8px;
}

a {
    font-weight: 500;
    color: white;
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    transition: 0.3s;
}

button {
    font-family: 'Poppins';
    cursor: pointer;
}

hr {
    border: 1px solid var(--grey-tertiary);
}

.vertical-line {
    border-left: 2px solid var(--text-secondary);
    height: 100%;
    margin: 0 12px 0 12px;
}

.colored-button {
    background-color: var(--accent-color) !important;
    color: var(--text-primary);
    font-size: 16px;
}

.hover-up {
    transition: 0.3s;
    cursor: pointer;
}

.hover-up:hover,
.hover-up:active:not(:hover) {
    transition: 0.3s;
    transform: translateY(-4px);
    box-shadow: 0 6px 0 0 var(--shadow-tint);
}

.hover-up:active {
    box-shadow: none;
    transform: translateY(0);
    transition: 0.1s;
}

.section-title {
    margin-bottom: 12px;
}

/*#endregion Global Definitions*/

/*#region radio grade picker */
input[type="radio"]:checked+label {
    background-color: var(--accent-color);
    color: var(--text-primary);
    font-weight: 600;
}

.radio-main {
    background-color: var(--frosted-blue);
    border-radius: 8px 8px 0 0;
    margin: 12px;
    margin-bottom: 0 !important;
    margin-right: 8px;
    padding: 8px 8px 8px 12px;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: 4px solid var(--accent-color);
    box-shadow: 0 0 40px var(--shadow-tint);
}

.radio-main-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.radio-main p {
    line-height: 0;
    margin-right: 12px;
    min-width: fit-content;
}

.radio-main input {
    display: none;
}

.radio-main label {
    cursor: pointer;
    min-width: 80px;
    margin: 4px;
    min-height: 24px;
    background-color: var(--grey-primary);
    color: var(--text-secondary);
    border-radius: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

/*#endregion radio grade picker */

/*#region Header - Footer*/

.header {
    height: 40px;
    width: calc(100% - 60px);
    padding: 20px;
    margin-left: 12px;
    border-radius: 0 0 6px 6px;
    background-image: url(../img/wide-sj.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-frost {
    height: 40px;
    width: calc(100% - 64px);
    padding: 20px;
    margin-left: 12px;
    border-radius: 0 0 6px 6px;
    background: linear-gradient(to right, var(--frosted-blue), transparent);
    backdrop-filter: blur(4px);
    mask: linear-gradient(to right, black, rgba(0, 0, 0, 0.7), transparent);
    position: absolute;
}

.header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 99;
}

.header button {
    z-index: 99;
}

.header-left img {
    width: 24px;
    height: 24px;
    margin: 0 12px 0 6px;
}

.greeting {
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 1.2;
    padding: 8px;
    border: none;
    font-weight: 600;
    color: var(--text-primary);
}

.greeting-sentence {
    font-weight: 200;
    color: var(--text-secondary);
}

.footer {
    background-color: var(--grey-secondary);
    border-radius: 8px 8px 0 0;
    margin: 12px 8px 0 12px;
    line-height: 1;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    bottom: 0;
    width: calc(100% - 52px);
}

.footer-links {
    text-align: right;
    width: auto;
    color: var(--text-primary);
    font-size: calc(0.3em + 0.5vw);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-links a {
    padding-left: 8px;
}

.language-selector {
    width: fit-content;
    height: inherit;
    padding-left: 8px;
    line-height: inherit;
    display: contents;

}

.language-selector select {
    background-color: var(--grey-secondary);
    font-family: inherit;
    border: none;
    color: var(--text-primary);
    font-weight: inherit;
    padding: 4px;
    padding-left: 8px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    font-size: inherit;
}

.language-selector select:focus {
    outline: none;
}

.language-selector select option {
    background-color: var(--grey-secondary);
    color: var(--text-secondary);
    cursor: pointer;
}

.link-dot {
    color: var(--grey-tertiary);
    padding-left: 8px;
}

/*#endregion Header - Footer*/

.quick-access-wrapper {
    background-color: var(--grey-secondary);
    border-left: 4px solid var(--accent-color);
    box-shadow: 0 0 40px var(--shadow-tint);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    margin: 12px;
    margin-right: 8px;
    padding: 8px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.quick-access-card {
    align-items: center;
    background-color: var(--frosted-blue);
    backdrop-filter: blur(8px);
    min-width: 140px;
    width: 140px;
    margin: 4px;
    display: flex;
    justify-content: center;
    height: 48px;
    border-radius: 8px;
    flex-grow: 1;
}

.quick-access-card:hover {
    opacity: 1;
    background-color: var(--accent-color);
}

/*#region Alerts*/
.announcements-tint {
    width: 100%;
    border-radius: 0 0 8px 8px;
    height: 120px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
    backdrop-filter: blur(4px);
    mask: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.announcements-wrapper {
    background-color: var(--grey-secondary);
    border-left: 4px solid var(--alert-secondary);
    box-shadow: 0 0 40px var(--shadow-tint);
    border-radius: 8px;
    margin: 12px;
    margin-right: 8px;
    padding: 12px;
    padding-bottom: 2px;
    text-align: left;
    position: relative;
}

.announcements-content {
    height: 0;
    opacity: 0;
    transition: opacity 0s, height 0.3s ease;
    pointer-events: none;
}

.announcements-content.visible {
    display: block;
    opacity: 1;
    overflow-x: hidden;
    height: 220px;
    pointer-events: all;
}

#alert-wrapper,
#announcement-wrapper {
    overflow-y: scroll;
    /* max-height: 200px; */
}

.alert-item,
.announcement-item {
    border-radius: 8px;
    padding: 4px;
    transition: 0.3s;
    width: calc(100% - 8px);
    margin-bottom: 4px;
}

.alert-item {
    background-color: var(--alert-secondary);
}

.announcement-item {
    background-color: var(--grey-tertiary);
}

.announcement-item h2,
.alert-item h2 {
    color: var(--text-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    margin: 12px;
}

.announcement-item h2 span,
.alert-item h2 span {
    color: var(--text-primary);
    font-weight: 200;
    font-size: 14px;
    align-self: center;
    margin-left: 8px;
}

.announcement-item p,
.alert-item p {
    line-height: 1.2;
    margin: 12px !important;
    color: var(--text-primary)
}

/*#endregion Alerts*/

/*#region Subjects*/

/* .subjects-widget {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    border: none;
    margin: 12px;
    margin-top: 0 !important;
    padding-bottom: 16px;
    margin-right: 8px;
    border-radius: 0 0 8px 8px;
    background: var(--grey-secondary);
    transition: 0.3s;
    border-left: 4px solid var(--accent-color);
    box-shadow: 0 0 40px var(--shadow-tint);
}

.subject-card {
    align-items: center;
    margin: 8px 8px 0 12px;
    width: calc(25% - 22px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 140px;
    background-size: cover;
    border-radius: 8px;
}

.subject-card h1 {
    font-size: 24px;
    text-shadow: 0 0 16px black;
    font-weight: 600 !important;
    text-align: center;
    width: 100%;
    height: 120%;
    margin: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--shadow-tint);
    transition: 0.3s;
}

.subject-card h1:hover {
    backdrop-filter: blur(0px);
} */

.subjects-widget-new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 12px 8px 12px 12px;
    margin-top: 0 !important;
    padding-bottom: 16px;
    border-radius: 0 0 8px 8px;
    background: var(--grey-secondary);
    transition: 0.3s;
    border-left: 4px solid var(--accent-color);
    box-shadow: 0 0 40px var(--shadow-tint);
}

.subject-card {
    align-items: center;
    margin: 8px;
    width: 200px;
    height: 276px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    display: inline-block;
    background-color: var(--grey-primary);
}

.subject-icon {
    align-items: center;
    margin: 8px;
    max-width: 186px;
    height: 186px;
    background-size: cover;
    border-radius: 8px;
}

.subject-card h1 {
    font-size: 20px;
    font-weight: 600 !important;
    border-radius: 4px;
    padding: 0 8px 8px 8px;
    text-align: center;
    transition: 0.3s;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subject-card h1:hover {
    backdrop-filter: blur(0px);
}

/*#region SubjectBackgrounds*/
#mathématiques {
    width: 100%;
    background-position: center;
    background-image: url(../img/mathématiques.jpg);
}

#français {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/français.jpg);
}

#english {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/english.jpg);
}

#görselsanatlar {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/görselsanatlar.jpg);
}

#sciences {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/chimie.jpg);
}

#bilgisayarbilimi {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/bilgisayarbilimi.jpg);
}

#bedeneğitimi {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/bedeneğitimi.jpg);
}

#türkdiliveedebiyatı {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/türkdiliveedebiyatı.jpg);
}

#seçmelitürkdiliveedebiyatı {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/seçmelitürkdiliveedebiyatı.jpg);
}

#physique {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/physique.jpg);
}

#chimie {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/chimie.jpg);
}

#biologie {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/biologie.jpg);
}

#felsefe {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/felsefe.jpg);
}

#sosyoloji {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/sosyoloji.jpg);
}

#mantık {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/mantık.jpg);
}

#tarih {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/tarih.jpg);
}

#coğrafya {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/coğrafya.jpg);
}

#tci̇nkılaptarihiveatatürkçülük {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/tcinkılaptarihiveatatürkçülük.jpg);
}

#çağdaştürkvedünyatarihi {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/çağdaştürkvedünyatarihi.jpg);
}

#müzik {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/müzik.jpg);
}

#sağlıkbilgisivetrafikkültürü {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/sağlıkbilgisivetrafikkültürü.jpg);
}

#türkkültürvemedeniyettarihi {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/türkkültürvemedeniyettarihi.jpg);
}

#psikoloji {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/psikoloji.jpg);
}

#dinkültürüveahlakbilgisi {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/dinkültürüveahlakbilgisi.jpg);
}

#emi {
    width: 100%;
    background-position: center;
    background-image:
        url(../img/emi.jpg);
}

/*#endRegion SubjectBackgrounds*/

/*#endregion Subjects*/

/*#region Media Queries*/
@media screen and (max-width: 1024px) {
    .radio-main {
        flex-direction: column;
        height: auto;
    }
}

@media screen and (max-width: 500px) {
    .subject-card {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin: 4px;
    }

    .subject-icon {
        max-width: 100px;
        height: 84px;
    }

    .subject-card h1 {
        font-size: 20px;
        text-align: left;
    }
}

/*#endregion Media Queries*/

.pencil {
    display: block;
    width: 10em;
    height: 10em;
  }
  
  .pencil__body1,
  .pencil__body2,
  .pencil__body3,
  .pencil__eraser,
  .pencil__eraser-skew,
  .pencil__point,
  .pencil__rotate,
  .pencil__stroke {
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .pencil__body1,
  .pencil__body2,
  .pencil__body3 {
    transform: rotate(-90deg);
  }
  
  .pencil__body1 {
    animation-name: pencilBody1;
  }
  
  .pencil__body2 {
    animation-name: pencilBody2;
  }
  
  .pencil__body3 {
    animation-name: pencilBody3;
  }
  
  .pencil__eraser {
    animation-name: pencilEraser;
    transform: rotate(-90deg) translate(49px,0);
  }
  
  .pencil__eraser-skew {
    animation-name: pencilEraserSkew;
    animation-timing-function: ease-in-out;
  }
  
  .pencil__point {
    animation-name: pencilPoint;
    transform: rotate(-90deg) translate(49px,-30px);
  }
  
  .pencil__rotate {
    animation-name: pencilRotate;
  }
  
  .pencil__stroke {
    animation-name: pencilStroke;
    transform: translate(100px,100px) rotate(-113deg);
  }
  
  /* Animations */
  @keyframes pencilBody1 {
    from,
      to {
      stroke-dashoffset: 351.86;
      transform: rotate(-90deg);
    }
  
    50% {
      stroke-dashoffset: 150.8;
   /* 3/8 of diameter */
      transform: rotate(-225deg);
    }
  }
  
  @keyframes pencilBody2 {
    from,
      to {
      stroke-dashoffset: 406.84;
      transform: rotate(-90deg);
    }
  
    50% {
      stroke-dashoffset: 174.36;
      transform: rotate(-225deg);
    }
  }
  
  @keyframes pencilBody3 {
    from,
      to {
      stroke-dashoffset: 296.88;
      transform: rotate(-90deg);
    }
  
    50% {
      stroke-dashoffset: 127.23;
      transform: rotate(-225deg);
    }
  }
  
  @keyframes pencilEraser {
    from,
      to {
      transform: rotate(-45deg) translate(49px,0);
    }
  
    50% {
      transform: rotate(0deg) translate(49px,0);
    }
  }
  
  @keyframes pencilEraserSkew {
    from,
      32.5%,
      67.5%,
      to {
      transform: skewX(0);
    }
  
    35%,
      65% {
      transform: skewX(-4deg);
    }
  
    37.5%, 
      62.5% {
      transform: skewX(8deg);
    }
  
    40%,
      45%,
      50%,
      55%,
      60% {
      transform: skewX(-15deg);
    }
  
    42.5%,
      47.5%,
      52.5%,
      57.5% {
      transform: skewX(15deg);
    }
  }
  
  @keyframes pencilPoint {
    from,
      to {
      transform: rotate(-90deg) translate(49px,-30px);
    }
  
    50% {
      transform: rotate(-225deg) translate(49px,-30px);
    }
  }
  
  @keyframes pencilRotate {
    from {
      transform: translate(100px,100px) rotate(0);
    }
  
    to {
      transform: translate(100px,100px) rotate(720deg);
    }
  }
  
  @keyframes pencilStroke {
    from {
      stroke-dashoffset: 439.82;
      transform: translate(100px,100px) rotate(-113deg);
    }
  
    50% {
      stroke-dashoffset: 164.93;
      transform: translate(100px,100px) rotate(-113deg);
    }
  
    75%,
      to {
      stroke-dashoffset: 439.82;
      transform: translate(100px,100px) rotate(112deg);
    }
  }