/*#region Scrollbar*/

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--grey-tertiary);
    border-radius: 8px;
    animation: ease-in-out 0.3s;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--grey-secondary);
    animation: ease-in-out 0.3s;
}

/*#endregion Scrollbar*/

/*#region Banner*/
.subject-header {
    height: 40px;
    width: calc(100% - 60px);
    padding: 20px;
    margin: 0 12px 12px 12px;
    border-radius: 0 0 6px 6px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image:
        linear-gradient(to bottom, rgba(49, 66, 104, .5), rgba(49, 66, 104, .5)),
        url(../img/mathématiques.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subject-header button {
    z-index: 1;
}

/*#endregion Banner*/

/*#region Admin Popup*/
.admin-popup {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 99;
}

.admin-popup-wrapper {
    background-color: var(--grey-primary);
    margin: 40px;
    border-radius: 8px;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.admin-popup-header {
    display: flex;
    z-index: 99;
    background-color: var(--grey-secondary);
    width: 100%;
    border-radius: 8px 8px 0 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    text-align: center;
    align-items: center;
}

.admin-popup-header h2{
    margin: auto;
    overflow-wrap: break-word;
    text-overflow:inherit;
}

.admin-popup-header button {
    background-color: var(--alert-secondary);
    margin: 8px;
}

/*#endregion Admin Popup*/

/*#region Texteditor Popup*/
.texteditor-popup {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 99;
}

.texteditor-popup-wrapper {
    background-color: var(--grey-primary);
    margin: 40px;
    border-radius: 8px;
    max-height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.texteditor-popup-header {
    display: flex;
    z-index: 99;
    background-color: var(--grey-secondary);
    width: 100%;
    border-radius: 8px 8px 0 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}

.texteditor-popup-header button {
    background-color: var(--alert-secondary);
    margin: 8px;
}

.texteditor-popup-body {
    overflow-y: scroll;
    overflow-x: hidden;
}

/*#endregion Texteditor Popup*/

/*#region Topics Popup*/
.topics-popup {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 99;
}

.topics-popup-wrapper {
    background-color: var(--grey-primary);
    margin: 40px;
    border-radius: 8px;
    max-height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.topics-popup-header {
    display: flex;
    z-index: 99;
    background-color: var(--grey-secondary);
    width: 100%;
    border-radius: 8px 8px 0 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}

.topics-popup-header button {
    background-color: var(--alert-secondary);
    margin: 8px;
}

.topics-popup-body {
    overflow-y: scroll;
    overflow-x: hidden;
}

.add-material-popup {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 99;
}

.add-material-popup-wrapper {
    background-color: var(--grey-primary);
    margin: 40px;
    border-radius: 8px;
    max-height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.add-material-popup-header {
    display: flex;
    z-index: 99;
    background-color: var(--grey-secondary);
    width: 100%;
    border-radius: 8px 8px 0 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    text-align: center;
    align-items: center;
}

.add-material-popup-header h2{
    margin: auto;
    overflow-wrap: break-word;
    text-overflow: clip;
}

.add-material-popup-header button {
    background-color: var(--alert-secondary);
    margin: 8px;
}

.add-material-popup-body {
    overflow-y: scroll;
    overflow-x: hidden;
}

/*#endregion Topics Popup*/

/*#region Announcements */


.announcement-item-title,
.alert-item-title {
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    background-color: var(--grey-secondary);
}

.alert-item-title {
    background-color: var(--alert-secondary);
}

.announcement-item-title span,
.alert-item-title span {
    font-size: 12px;
    color: var(--text-secondary);
    font-weight: 200;
}

.announcement-topics-red3,
.announcement-topics-normal4 {
    background-color: var(--grey-secondary);
    border-radius: 0 0 8px 8px;
    padding: 8px;
    font-weight: 200;
}

.no-alerts-text,
.no-mats-text {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 200;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--grey-primary);
}

.no-mats-text {
    margin-bottom: 12px;
}

/*#endregion Announcements */

/*#region Materials*/

.unit-radio-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--grey-primary);
}

.materials-wrapper {
    padding: 12px;
    margin: 12px;
    margin-right: 8px;
    border-radius: 8px;
    padding-bottom: 1px;
    background-color: var(--grey-secondary);
    border-left: 4px solid var(--accent-color);
}

.unit-radio,
.topic-radio {
    background-color: var(--grey-secondary);
    border-radius: 8px 8px 0 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: 0;
    margin-right: 8px;
    margin-left: 8px;
    padding-top: 8px;
    margin-bottom: 8px;
    filter: blur(0px 10vw 0px 0px);
    max-height: 45px;
}

.topic-radio {
    border-radius: 0 0 8px 8px;
    padding-top: 8px;
}

.unit-radio-wrapper p {
    line-height:normal;
    margin-left: 8px;
}

.unit-radio input,
.topic-radio input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.unit-radio label,
.topic-radio label {
    display: inline-block;
    cursor: pointer;
    width: fit-content;
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 4px;
    background-color: var(--grey-tertiary);
    color: var(--text-secondary);
    border-radius: 4px;
    text-align: center;
    line-height: 2;
    text-wrap: nowrap;
    align-self: top;
    transition: 0.3s;
}

.unit-radio span,
.topic-radio span {
    display: block;
    margin-left: 16px;
    min-width: 50px;
    width: calc(fit-content + 10px);
    align-self: center;
}

.unit-option-wrapper,
.topic-option-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 8px;
}

.subject-textfield {
    aspect-ratio: 1/1.41;
    border: none;
    border-radius: 8px;
    align-items: center;
    min-height: 20vh;
    max-width: max-content;
    max-height: 100%;
    overflow-y: scroll;
    left: 0;
    margin: auto auto auto 0;
}

.deselect-button {
    background-color: var(--grey-tertiary);
    border-radius: 4px;
    border: none;
    padding: 4px;
    font-size: 14px;
    color: var(--text-primary);
    margin-left: 4px;
    margin-top: 3px;
    height: 30px !important;
}

.textmat-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 12px 12px 0;
}

.subject-textmat {
    margin: 12px;
    aspect-ratio: 1/1.41;
}

.subject-textmat a {
    color: -webkit-link;
    text-decoration: underline;
}

.materials-title {
    font-size: 36px;
    font-weight: 700;
    margin: 0 12px 12px 12px;
}

.materials-content {
    justify-content: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: visible;
    overflow-x: hidden;
    padding-top: 12px;
    margin-bottom: 8px;
}

.add-material-item {
    margin: 6px;
    height: 300px;
    width: calc(25% - 18px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--frosted-blue);
    border-radius: 4px;
    border-left: 4px solid var(--accent-color);
}

.add-material-item p {
    color: var(--text-secondary);
    text-align: center;
}
.material-item {
    margin: 6px;
    height: 320px;
    width: calc(20% - 18px);
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    /* border-left: 6px solid var(--grey-primary); */
    transition: 0.3s;
    border-left: 4px solid var(--accent-color);
}

.materials-search {
    margin: 8px;
    padding: 12px;
    border-radius: 8px;
    background-color: var(--grey-secondary);
}

.material-search-results {
    justify-content: left;
    background-color: var(--grey-primary);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 8px;
}

.material-search-result{
    margin: 6px;
    padding-top: 4px;
    height: 160px;
    width: 210px;
    background-color: var(--grey-secondary);
    font-size: 16px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    transition: 0.3s;
    border-left: 4px solid var(--accent-color);
}

.material-search-result-title {
    font-weight: 600;
    margin: 0 0 0 6px;
    font-size: 14px;
    color: var(--text-primary);
    max-height: 40px;
    overflow-y: hidden;
}

.material-search-result-topic {
    margin: 2px 0 0 6px;
    font-size: 12px;
    color: var(--text-secondary);;
}

.material-search-result iframe {
    margin-left: 4px;
    height: 90px;
    width: 200px;
    border-radius: 4px;
    border: none;
}

.material-item-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 300px;
    border-radius: 4px 4px 0 0;
    border: none;
    scrollbar-width: 1px;
    scroll-behavior: smooth;
    scroll-margin: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    pointer-events: all; /* Bunu sonra değiştirebiliriz gerekirse */
}

.material-item-info {
    padding: 8px;
    border-radius: 0 0 4px 0;
    cursor: pointer;
    background-color: var(--grey-primary);
    bottom: 220px;
}

.material-admin-actions {
    padding: 0 8px 8px 8px;
    border-radius: 0 0 4px 0;
    cursor: pointer;
    background-color: var(--grey-primary);
}

.material-admin-actions button {
    margin-right: 8px;
}

.material-item-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    margin-bottom: 4px;
}

.material-item-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.material-item-detail {
    font-size: 12px;
    color: var(--text-secondary);
    margin-right: 8px;
}

.material-item-remove {
    cursor: "pointer";
    display: contents;
}

.material-item-remove svg{
    border-radius: 4px;
    padding: 4px;
    font-size: 20px;
    background-color: var(--alert-secondary);
    color: var(--text-secondary);
}
/*#endregion Materials*/

@media screen and (max-width: 1200px) {
    .material-item {
        width: calc(calc(100%/3) - 18px);
    }
}

@media screen and (max-width: 800px) {
    .material-item {
        width: calc(50% - 18px);
        /* height: calc(50% - 18px); */
    }
}

@media screen and (max-width: 500px) {
    .material-item {
        width: calc(100% - 18px);
        /* height: 100px; */
    }

    .unit-radio,
    .topic-radio {
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: visible;
        overflow-x: hidden;
        max-height: 160px;
    }

    .admin-popup-wrapper {
        margin: 8px 0 8px 8px;
        max-height: calc(100vh - 16px);
    }

}

/*#endregion Media Queries*/