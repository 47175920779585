.body-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image:
    linear-gradient(rgba(36, 52, 62, 0.8), rgba(0, 99, 156, 0.8)),
    url(../img/404page.png);
    backdrop-filter: blur(80px);
    background-position: bottom;
    background-size: cover;
    text-align: center;
}

.text-404 {
    font-size: 60px;
    font-weight: 900;
    line-height: 0;
    padding: 10px 20px;
}

.description-404 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom:20px;
    width: auto;
    padding: 10px 20px;
    color: var(--text-primary);
    line-height: 1.5;
}

.description-404-secondary {
    color: var(--text-secondary);
}