.menu-widget {
    background-color: var(--grey-secondary);
    border-left: 4px solid var(--accent-color);
    box-shadow: 0 0 40px var(--shadow-tint);
    border-radius: 8px;
    margin: 12px;
    margin-right: 20px;
    padding: 12px;
    padding-bottom: 2px;
    text-align: left;
}

.menu-day-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: left;
    width: 100%;
}

.menu-day {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 8px;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--frosted-blue);
    flex-basis: 301px;
    height: 310px;
    flex-grow: 1;
}

.menu-day h3 {
    margin: 0 8px 0 8px;
}

.menu-day p {
    margin: 8px 0 0 8px;
    color: var(--text-secondary);
    font-size: 12px;
}

.menu-contents {
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 4px;
    margin: 6px 0 6px 0;
}

.menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 8px;
    background-color: var(--grey-tertiary);
}

.menu-item p {
    margin: 0;
    color: var(--text-primary);
    font-size: 12px;
}

.menu-cals {
    color: var(--text-secondary) !important;
    font-style: italic;
}