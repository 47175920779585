.login-body {
    background: linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.8)), url(../img/login.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: calc(100vw - 24px);
    max-height: 100vh;
    overflow-y: hidden !important;
    padding: 12px;
}

.login-greeting {
    font-size: 32px;
}

.login-desc {
    color: var(--text-secondary);
}

.login-disclaimer {
    border: 1px solid var(--text-secondary);
    padding: 12px 8px 8px 8px;
    border-radius: 8px;
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 24px) !important;
}

.login-disclaimer div {
    height: 100%;
}

.login-disclaimer span {
    text-align: left;
    margin-left: 8px;
}

.login-disclaimer span i {
    color: var(--text-secondary);
}